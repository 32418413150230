/** @jsx jsx */
import Navbar from '../../components/Navbar/Navbar';
import { pandesalztshirt } from '../../assets/images';
import { jsx, css } from '@emotion/core';

function MerchPage() {
  return (
    <div
      css={css`
        background: white;
        height: 100vh;
        width: 100vw;
        
        animation: 1s fadein linear forwards;
      `}
    >
      <div css={css`padding: 0 10px 100px; max-width: 800px; margin: 0 auto;`}>
        <Navbar />
        <img src={pandesalztshirt} alt="tshirt" style={{maxWidth: '300px'}} />
        <h1>Limited-Edition PANDESALZ tee</h1>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <h2 style={{textDecoration: 'line-through'}}>$400.00</h2>
          <button disabled style={{fontFamily: 'sans-serif', background: 'grey', color: '#f1f1f1', padding: '5px 20px', marginLeft: '20px'}}>Buy Now</button>
        </div>
        <h2 style={{color: 'tomato'}}>SOLD OUT!</h2>
      </div>
    </div>
  )
}

export default MerchPage;