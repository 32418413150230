/** @jsx jsx */
import Navbar from '../../components/Navbar/Navbar';
import { jsx, css } from '@emotion/core';
import { pandesalcropped } from '../../assets/images';

function AboutUsPage() {
  return (
    <div 
      css={css`
        margin: 0 auto;
        max-width: 800px;
        padding: 0 5px 100px;

        animation: 1s fadein linear forwards;
      `}
    >
      <Navbar />
      <h1>About Us</h1>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <p>Homecook from Queens, New York</p>
      </div>
      <img 
        src={pandesalcropped} 
        alt="baking pandesal" 
        css={css`
          border: 10px solid var(--pandesalz-white);
          width: 100%;
          max-width: 500px;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
        `}
      />
    </div>
  )
}

export default AboutUsPage;