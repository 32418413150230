/** @jsx jsx */
import Navbar from '../../components/Navbar/Navbar';
// import { pandesalzmap } from '../../assets/images';
import { jsx, css } from '@emotion/core';
import Obfuscate from 'react-obfuscate';

function OrderPage() {
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 800px;
        padding: 0 5px 100px;
        animation: 1s fadein linear forwards;
      `}
    >
      <Navbar />
      <h1>To order:</h1>
      <h2>
        <span>Please email us at </span>
        <span>
          <Obfuscate
            email="pandesalznyc@gmail.com"
          />
        </span>
      </h2>
      <h3>All orders must be put in 3 days in advance</h3>
    </div>
  )
}

export default OrderPage;