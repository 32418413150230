/** @jsx jsx */
import React from 'react';
import moment from 'moment-timezone';
import { Carousel, Tooltip } from 'antd';
import { ImInstagram } from 'react-icons/im';
import { AiFillMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import {
  pandesalzlogo,
  minibibingka,
  lotsofood,
  morefood,
  pan1,
  pan2,
  nyc,
  tsokotella
} from '../../assets/images';

const Button = styled.button`
  font-family: 'Lobster', cursive;
  font-size: 18px;
  background: inherit;
  color: var(--pandesalz-white);
  border: 0;
  padding: 0 4px;
  margin-bottom: 8px;

  :hover {
    background: var(--pandesalz-yellow);
    color:#212121;
  }
`;

function HomePage() {
  const [time, setTime] = React.useState(moment().tz('America/New_York').format('L h:mm:ssa'));
  
  React.useEffect(() => {
    setInterval(() => {
      setTime(moment().tz('America/New_York').format('L h:mm:ssa'));
    }, 1000);
  });

  return (
    <div className="HomePage" css={css`background:#242424; z-index: -1;`}>
      <div 
        className="MainContainer"
        css={css`
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          align-content: center;
          padding-top: 150px;
          margin: 0 auto;
          height: 100%;

          @media (max-width: 768px){ 
            flex-direction: column;
            padding: 10px 0 20px;
          }
        `}
      > 
        <div  
          css={css`
            background-image: url(${nyc});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            filter: grayscale(100%) brightness(0.15);
            z-index: 0;
            min-height: 900px;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            left: 0;
          `}
        >
        </div>
        <div 
          className="MainContainer_left" 
          css={css`
            width: 300px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            align-content: center;
            padding-top: 20px;
            z-index: 2;

            animation: 1s fadein linear forwards;
          `}
        >
          <img 
            className="pandesalzlogo" 
            src={pandesalzlogo} 
            alt="pandesalzlogo"
            css={css`
              z-index: 1000;
              height: 200px;
              width: 200px;
              display: flex;
              justify-content: center;
              margin-bottom: 5px;
              border-radius: 50%;
              border: 4px solid var(--pandesalz-white);
            `}
          />
          <p style={{fontFamily: `'Courier New', Courier`, fontWeight: '700', fontSize: '14px', color: 'var(--pandesalz-white)'}}>
            <time dateTime={time}>{time} </time>
            <span>NYC</span>
          </p>
          <nav css={css`padding: 10px 0 20px;`}>
            <ul 
              css={css`
                text-align: left;
                margin: 0 0 10px;
                list-style: none;
              `}
            >
              <li><Link to="/menu"><Button>menu</Button></Link></li>
              <li><Link to="/order"><Button>order now</Button></Link></li>
              <li><Link to="/aboutus"><Button>about us</Button></Link></li>
              <li><Link to="/merch"><Button>shop merch</Button></Link></li>
            </ul>
          </nav>
          <div 
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '20px'
              }}
            >
              <Tooltip title="Follow us on Instagram!">
                <a href="https://www.instagram.com/pandesalz/"  target="_blank" rel = "noopener noreferrer">
                  <ImInstagram 
                    css={css`
                      color: var(--pandesalz-white);
                      padding-top: 4px;
                      font-size: 22px;

                      :hover {
                        color:var(--pandesalz-yellow);
                      }
                    `}
                  />
                </a>
              </Tooltip>
              <Link to="/order">
                <AiFillMail 
                  css={css`
                    color: var(--pandesalz-white);
                    padding-top: 5px;
                    margin-left: 6px;
                    font-size: 28px;

                    :hover {
                      color:var(--pandesalz-yellow);
                    }
                  `}
                />
              </Link>
            </div>
        </div>
        <div 
        className="MainContainer_right" 
          css={css`
            margin-top: 150px;
            max-width: 500px;

            @media (max-width: 768px){ 
              margin-top: 15px;
            }

            animation: 1s fadein linear forwards;
          `}
        >
          <Carousel autoplay effect="fade" style={{ height: '350px', width: '350px', border: '5px solid var(--pandesalz-white)', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>            
            <img src={minibibingka}  alt="img" />
            <img src={lotsofood}  alt="img" />
            <img src={morefood}  alt="img" />
            <img src={pan1}  alt="img" />
            <img src={pan2}  alt="img" />
            <img src={tsokotella}  alt="img" />
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default HomePage;