import React from 'react';
import { Global, css } from '@emotion/core'

function GlobalStyles() {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Lobster&family=Merienda:wght@400;700&family=Vast+Shadow&display=swap');

        :root {
          --pandesalz-yellow: #fedf5b;
          --pandesalz-black:#212121;
          --pandesalz-white: #f5f5f5;
        }

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        body {
          margin: 0;
          font-size: 14px;
          background: #fff6e6;
          color: var(--pandesalz-black);
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        

        ::selection {
          background: var(--pandesalz-yellow);
          color: var(--pandesalz-black);
        }
        
        @keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        button {
          cursor: pointer;
          background: inherit;
          border: 0;
          color: var(--pandesalz-black);
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        a {
          text-decoration: none;
        }

        *,*:focus,*:hover{
          outline:none;
        }
      `}
    />
  )
}

export default GlobalStyles;
