/** @jsx jsx */
import Navbar from '../../components/Navbar/Navbar';
import PicModal from '../../components/PicModal/PicModal';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import {
  plainpandesal,
  cheesepandesal,
  ubepandesal,
  tsokotella,
  minibibingka,
  lumpia,
  asado,
  // lotsofood,
  // morefood,
  pandesalart,
  hotfoodart,
  frozenart,
  veggielumpia,
  veggielumpiasmall,
  ubepandesalsmall,
  plainpandesalsmall,
  asadosmall,
  bibingka,
  bibingkasmall,
  pancit,
  pancitsmall,
  tocino,
  tocinosmall
} from '../../assets/images';

const MenuPageDiv = styled.div`
  margin: 0 auto;
  max-width: 1500px;
  font-family: 'Merienda', cursive;
  padding: 0 5px 100px;
  animation: 1s fadein linear forwards;
`;

const CategoryDiv = styled.div``;

const CategoryBox = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 40px;
  max-width: 500px;
  min-width: 450px;
  margin: 0 20px 20px;
  background: #fff9f0;

  @media (max-width: 500px){ 
    padding: 5px 12px;
    margin: 0 5px 20px;
    min-width: 360px;
  }
`;

const CategoryHeader = styled.h1`
  text-align: center;
  font-family: 'Lobster', cursive;
  font-size: 32px;
  color: #AB7815;
  margin-bottom: 0;

  @media (max-width: 500px){ 
    padding: 0;
    margin: 0;
    font-size: 28px;
  }
`;

const FoodDiv = styled.div`
  margin-bottom: 20px;
`;

const FoodHeader = styled.h2`
  text-align: center;
  font-family: 'Lobster', cursive;
  font-size: 28px;
  color: var(--pandesalz-black);
  margin: 0;

  @media (max-width: 500px){ 
    font-size: 24px;
  }
`;

const FoodHeaderCaption = styled.p`
  text-align: center;
  font-family: 'Merienda', cursive;
  font-size: 12px;
  color: var(--pandesalz-black);


  position: relative;
  top: -0.4em;

`;

const HorizontalRule = styled.hr`
  padding: 0;
  border: none;
  border-top: medium double #AB7815;
  color: #AB7815;
  text-align: center;
  margin-bottom: 20px;

  :after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.9em;
    font-size: 1em;
    padding: 0 0.25em;
    background: #fff9f0;
  }
`;


function TableRowItem({img, thumbnail, flavor, firstcost, secondcost}) {
  return (
    <tr>
      <td css={css`padding: 5px 10px 5px 0;`}><PicModal thumbnail={thumbnail} img={img} /></td>
      <td css={css`padding-right: 50px;`}>{flavor}</td>
      <td>{firstcost}</td>
      <td>{secondcost}</td>
    </tr>
  )
}

function MenuPage() {
  return (
    <MenuPageDiv>
      <Navbar />
      <h1 
        css={css`
          padding: 20px 0;
          text-align: center;
          font-family: 'Vast Shadow';

          @media (max-width: 500px){ 
            padding: 0;
            margin: 0;
          }
        `}
      >
        PANDESALZ MENU
      </h1>
      <div 
        css={css`
          display: flex; 
          justify-content: space-between; 
          margin: 0 auto; 
          max-width: 1500px;

          @media (max-width: 1500px){ 
            flex-direction: column;
            max-width: 600px;
            padding: 10px 0 20px;
          }
        `}
      >
        <CategoryDiv>
          <CategoryHeader>baked goods <img css={css`width: 40px; height: 40px;`} src={pandesalart} alt="pandesalart" /></CategoryHeader>
          <CategoryBox>
            <FoodDiv>
              <FoodHeader>pandesal</FoodHeader>
              <FoodHeaderCaption>filipino bread rolls</FoodHeaderCaption>
              <table css={css`margin: 0 auto;`}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th css={css`padding-right: 20px;`}><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>half dozen</p></th>
                    <th><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>dozen</p></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowItem thumbnail={plainpandesalsmall} img={plainpandesal} flavor="classic plain" firstcost="$6" secondcost="$10" />
                  <TableRowItem thumbnail={ubepandesalsmall} img={ubepandesal} flavor="ube (w/ ube or cheese filling)" firstcost="$10" secondcost="$16" />
                  <TableRowItem img={tsokotella} flavor="tsokotella (chocotella)" firstcost="$10" secondcost="$16" />
                  <TableRowItem img={cheesepandesal} flavor="cheese" firstcost="$10" secondcost="$16" />
                  <TableRowItem img={cheesepandesal} flavor="cheese pimiento" firstcost="$10" secondcost="$16" />
                  <TableRowItem thumbnail={asadosmall} img={asado} flavor="chicken asado" firstcost="$12" secondcost="$20" />
                  <TableRowItem thumbnail={asadosmall} img={asado} flavor="pork asado" firstcost="$12" secondcost="$20" />
                  <TableRowItem thumbnail={asadosmall} img={asado} flavor="pork giniling" firstcost="$14" secondcost="$22" />
                </tbody>
              </table>
            </FoodDiv>
            <HorizontalRule/>
            <FoodDiv>
              <FoodHeader>bibingka</FoodHeader>
              <FoodHeaderCaption>filipino coconut rice cake</FoodHeaderCaption>
              <table css={css`margin: 0 auto;`}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th css={css`padding-right: 20px;`}><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>single</p></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowItem img={minibibingka} flavor="mini bibingka" firstcost="$3"  />
                  <TableRowItem thumbnail={bibingkasmall} img={bibingka} flavor="8-inch pan bibingka" firstcost="$10" />
                </tbody>
              </table>
            </FoodDiv>
          </CategoryBox>
        </CategoryDiv>

        <CategoryDiv>
          <CategoryHeader>cooked savory food <img css={css`width: 40px; height: 40px;`} src={hotfoodart} alt="hotfoodart" /></CategoryHeader>
          <CategoryBox>
            <FoodDiv>  
              <FoodHeader>shanghai lumpia (cooked)</FoodHeader>
              <FoodHeaderCaption>filipino spring rolls</FoodHeaderCaption>
              <table css={css`margin: 0 auto;`}>
                <tbody>
                  <TableRowItem thumbnail={veggielumpiasmall} img={veggielumpia} flavor="veggie (10pcs)" firstcost="$15"  />
                  <TableRowItem img={lumpia} flavor="chicken (24pcs)" firstcost="$15" />
                  <TableRowItem img={lumpia} flavor="pork (24pcs)" firstcost="$15" />
                </tbody>
              </table>
            </FoodDiv>
            <HorizontalRule />
            <FoodDiv>
              <FoodHeader>pancit noodles</FoodHeader>
              <FoodHeaderCaption>filipino noodles</FoodHeaderCaption>
              <p>1. pick your noodle style</p>
                <table css={css`margin: 0 auto;`}>
                  <tbody>
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="sotanghon (bean vermicelli)" />
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="canton (egg noodle)" firstcost="+$3" />
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="bihon (rice noodle)" firstcost="+$3" />
                  </tbody>
                </table>
              <p css={css`margin-top: 30px;`}>2. pick your topping and size</p>
                <table css={css`margin: 0 auto;`}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th css={css`padding-right: 20px;`}><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>small (1-2ppl)</p></th>
                      <th css={css`padding-right: 20px;`}><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>large (3-6ppl)</p></th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="veggie" firstcost="$10" secondcost="$25" />
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="chicken" firstcost="$12" secondcost="$30" />
                    <TableRowItem thumbnail={pancitsmall} img={pancit} flavor="pork" firstcost="$12" secondcost="$30" />
                  </tbody>
                </table>
            </FoodDiv>
          </CategoryBox>
        </CategoryDiv>

        <CategoryDiv>
          <CategoryHeader>frozen ready-to-cook food <img css={css`width: 40px; height: 40px;`} src={frozenart} alt="frozenart" /></CategoryHeader>
          <CategoryBox>
            <FoodDiv>  
              <FoodHeader>frozen tocino</FoodHeader>
              <FoodHeaderCaption>filipino tocino</FoodHeaderCaption>
              <table css={css`margin: 0 auto;`}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th css={css`padding-right: 20px;`}><p css={css`font-family: serif; font-size: 12px; text-decoration: underline;`}>16 oz.</p></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowItem thumbnail={tocinosmall} img={tocino} flavor="chicken tocino" firstcost="$6"  />
                  <TableRowItem thumbnail={tocinosmall} img={tocino} flavor="pork tocino" firstcost="$6" />
                </tbody>
              </table>
            </FoodDiv>
            <HorizontalRule />
            <FoodDiv>
              <FoodHeader>frozen shanghai lumpia</FoodHeader>
              <FoodHeaderCaption>filipino spring rolls</FoodHeaderCaption>
              <table css={css`margin: 0 auto;`}>
                <tbody>
                  <TableRowItem thumbnail={veggielumpiasmall} img={veggielumpia} flavor="veggie (10pcs)" firstcost="$10"  />
                  <TableRowItem img={lumpia} flavor="chicken (24pcs)" firstcost="$10" />
                  <TableRowItem img={lumpia} flavor="pork (24pcs)" firstcost="$10" />
                </tbody>
              </table>
            </FoodDiv>
          </CategoryBox>
        </CategoryDiv>
      </div>
      <div css={css`margin: 0 auto; text-align: center;`}>
        <h3>Note: We only offer pickup! All orders must be put in 3 days in advance. Thank you!</h3>
        <p>-- prices listed on website subject to change. Please contact us to confirm pricing.--</p>
      </div>
    </MenuPageDiv>
  )
}

export default MenuPage;