/** @jsx jsx */
import React from 'react';
import { Modal } from 'antd';
import { css, jsx } from '@emotion/core';
import { CloseSquareTwoTone } from '@ant-design/icons';

function PicModal({thumbnail, img}) {
  const [visible, changeVisible] = React.useState(false);

  const showModal = () => changeVisible(true);
  const handleCancel = () => changeVisible(false);
  
  return (
    <div 
      className="PicModal" 
      css={css`
        display: block;
        :hover {
          cursor: pointer;
        }
      `}
    >
      <img 
        src={thumbnail || img}  
        alt={thumbnail}
        css={css`
          width: 35px;
          height: 35px;
          border-radius: 50%;
          border: 1px solid lightgrey;

          :hover {
            border: 2px solid gold;
          }
        `}
        onClick={showModal}
      />
      <Modal
        visible={visible}
        onCancel={handleCancel}
        forceRender={false}
        footer={false}
        bodyStyle={{padding: 0}}
        closable={true}
        style={window.innerWidth < 600 && {top: 0}}
        closeIcon={<CloseSquareTwoTone style={{fontSize: '30px'}} twoToneColor="gold" />}
      >
        <img src={img} alt={img} style={{width: '100%', maxWidth: '640px', border: '10px solid var(--pandesalz-white)'}} />
      </Modal>
    </div>
  );   
}

export default PicModal;