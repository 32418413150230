/** @jsx jsx */
import Navbar from '../../components/Navbar/Navbar';
import { jsx, css } from '@emotion/core';

function PageNotFound() {
  return (
    <div css={css`max-width: 800px; padding: 10px 20px; margin: 0 auto;`}>
      <Navbar />
      <h1>Page not found</h1>
    </div>
  )
}

export default PageNotFound;