import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import MenuPage from './pages/MenuPage/MenuPage';
import OrderPage from './pages/OrderPage/OrderPage';
import MerchPage from './pages/MerchPage/MerchPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import GlobalStyles from './GlobalStyles';

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <Router>
        <Switch>
          <Route path="/" exact render={props => <HomePage />} />
          <Route path="/menu" exact render={props => <MenuPage />} />
          <Route path="/order" exact render={props => <OrderPage />} />
          <Route path="/merch" exact render={props => <MerchPage />} />
          <Route path="/aboutus" exact render={props => <AboutUsPage />} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
